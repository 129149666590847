<template>
  <div class="full">
    <!-- INICIO FORM LOGIN-->
    <div class="p-col-12 fullScreen">
      <div class="p-shadow-4 formlogin login">
        <div class="headerForm"><br /><br /><br /></div>
        <!--INICIO BODY FORM LOGIN-->
        <div v-if="ativo" class="bodyForm">
          <div class="p-d-flex p-ai-center p-jc-center p-fluid p-grid">
            <div class="p-field p-shadow-4 formulario">
              <div class="p-d-flex p-ai-center p-jc-center">
                <img src="../../assets/logoEstado.svg" class="ctis" />
              </div>
              <br />
              <div class="p-d-flex p-ai-center p-jc-center">
                <h1>Cadastro Confirmado.</h1>
              </div>
              <div class="p-d-flex p-ai-center p-jc-center">
                <h2>{{ email }}</h2>
              </div>
              <div class="p-d-flex p-ai-center p-jc-center"></div>
              <div class="p-d-flex p-ai-center p-jc-center">
                <div class="p-col-12 p-md-4">
                  <Button
                    label="Efetuar Login"
                    class="p-button-raised p-button-success hvr-grow"
                    :disabled="!ativo"
                    @click="$router.push('/')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--FIM FORM LOGIN-->
  </div>
</template>

<script>
import UsersService from "@/service/Users/UsersService";

export default {
  data() {
    return {
      email: this.$route.query.email,
      ativo: false,
    };
  },
  usersService: null,
  created() {
    this.usersService = new UsersService();
  },
  mounted() {
    this.activateUser();
  },
  methods: {
    activateUser() {
      this.usersService
        .ativarCadastro(this.email)
        .then((data) => {
          if (data.status === 204) {
            this.ativo = true;
            console.log("ativado com sucesso");
          }
        })
        .catch((error) => {
          this.ativo = false;
          this.$msgErro(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}
.fullScreen {
  height: 99vh;
  overflow: hidden;
}
.formlogin {
  height: 100%;
  margin-left: 10px;
}
.login {
  background-image: url("../../assets/fundo.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover*/
}
.headerForm {
  height: 20%;
}
.bodyForm {
  height: 70%;
}
.formulario {
  width: 30%;
  padding: 3%;
}
.ctis {
  width: 70%;
}
.full {
  margin: 0px auto;
}
@media screen and (max-width: 40em) {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 18px;
  }

  .painelGeral {
    display: none !important;
  }
  .formulario {
    width: 90%;
    padding: 3%;
  }
  .headerForm {
    height: 3%;
  }
  .bodyForm {
    height: 97%;
  }
  .ctis {
    width: 80%;
  }
}
@media screen and (max-width: 70em) {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 18px;
  }

  .painelGeral {
    display: none !important;
  }
  .formulario {
    width: 90%;
    padding: 3%;
  }
  .headerForm {
    height: 3%;
  }
  .bodyForm {
    height: 97%;
  }
  .ctis {
    width: 80%;
  }
}
</style>