import axios from "axios";
import AuthService from '../Auth/AuthService';
//import config from '../Auth/Config';

export default class UsersService extends AuthService {
  /* LISTAR TODOS */
  findAll() {
    return axios({
      method: 'get',
      url: this.api_url + 'usuarios?size=1000',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  /* LISTAR POR ID */
  findById(id) {
    return axios({
      method: 'get',
      url: this.api_url + 'usuarios/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  /* CADASTRAR */
  create(pessoa) {
    return axios({
      method: 'post',
      url: this.api_url + 'usuarios',
      //withCredentials: true,
      data: pessoa,
      /*headers: {
        'Authorization': 'Bearer ' + this.token(),
      }*/
    });
  }
  /* REMOVER */
  delete(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'usuarios/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* ATUALIZAR */
  update(id, pessoa) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + id,
      withCredentials: true,
      data: pessoa,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* RESETAR SENHA */
  resetePassword(id) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + id + "/resetar-password",
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* ALTERAR SENHA */
  updatePassword(id, dados) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + id + '/alterar-password',
      withCredentials: true,
      data: dados,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* RECUPERAR SENHA */
  restorePassword(email) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + email + '/recuperar-password',
    });
  }
  /* ATIVAR CADASTRO */
  activeUser(email) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + email + '/ativar-cadastro',
    });
  }
  /*ATIVAR E DESATIVAR USUARIO */
  updateStatus(id, dados) {
    return axios({
      method: 'put',
      url: this.api_url + 'usuarios/' + id + '/ativo',
      withCredentials: true,
      data: dados,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
        'Content-Type': 'application/json'
      }
    });
  }
}
